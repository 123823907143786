import React, { useState } from 'react'
import '../../assets/theme/theme.less'
import { LayoutComponent as Layout, TabsMenuComponent as TabsMenu, ButtonTabsComponent as ButtonTab, CheckboxComponent as Checkbox, ButtonComponent as Button } from '../../components'
import { Row, Col, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons';
import { generateReport } from '../../utils/functions';

const UsersReport = () => {
    const columns = [
        {
            title: "Nome",
            index: 'nome',
            checked: false
        },
        {
            title: "E-mail",
            index: 'email',
            checked: false
        },
        {
            title: "Telefone",
            index: 'telefone',
            checked: false
        },
        {
            title: "RG",
            index: 'rg',
            checked: false
        },
        {
            title: "CPF",
            index: 'cpf',
            checked: false
        },
        {
            title: "Data de nascimento",
            index: 'nascimento',
            checked: false
        },
        {
            title: "Data de cadastro",
            index: 'createdAt',
            checked: false
        },
        {
            title: "Endereço",
            index: 'endereco',
            checked: false
        },
        {
            title: "Número",
            index: 'numero',
            checked: false
        },
        {
            title: "Complemento",
            index: 'complemento',
            checked: false
        },
        {
            title: "Cidade",
            index: 'cidade',
            checked: false
        },
        {
            title: "Estado",
            index: 'estado',
            checked: false
        },
        {
            title: "CEP",
            index: 'cep',
            checked: false
        },
        {
            title: "Permissão",
            index: 'permissao',
            checked: false
        },
        {
            title: "Filiação",
            index: 'nomeMae',
            checked: false
        },
        {
            title: "Último login",
            index: 'ultimoLogin',
            checked: false
        },
    ]
    
    const [stateColumns, setStateColumns] = useState(columns)
    const [allChecked, setAllChecked] = useState(false)
    const [asa, setAsa] = useState(false)
    const [spirit, setSpirit] = useState(false)
    const [wallet, setWallet] = useState(false)
    const [filter, setFilter] = useState(null)
    const [error, setError] = useState(false)

    const onClick = (i) => {
        const aux = [...stateColumns]
        const current_state = !aux[i].checked
        aux[i].checked = current_state
        setStateColumns(aux)
    }

    const checkAll = () => {
        const aux = !allChecked
        setAllChecked(aux)
        const copy = [...stateColumns]

        copy.map(i => i.checked = aux)
        .then(() => setStateColumns(copy))
    }

    const onChangeFilter = (i) => {
        if(i === 1) {
            const aux = !asa
            setAsa(aux)
            setSpirit(false)
            setWallet(false)
            if(aux) {
                setFilter('asa')
            }
        } else if(i === 2) {
            const aux = !spirit
            setSpirit(aux)
            setAsa(false)
            setWallet(false)
            if(aux){
                setFilter('spirit')
            }
        } else {
            const aux = !wallet
            setWallet(aux)
            setSpirit(false)
            setAsa(false)
            if(aux) {
                setFilter('wallet')
            }
        }
    }

    const onSubmit = async () => {
        var aux = stateColumns.filter(x => x.checked === true)
        if(aux.length <= 0) {
            setError(true)
        } else {
            setError(false)
            var columns = []
            await aux.map(i => columns.push(i.index))
            generateReport(columns, 'users', filter)
        }
    }

    return (
        <Layout width>
            <Row>
                <Col md={24}>
                    <h1 className="title page-title">Clientes</h1>
                </Col>
                <Col md={24} className="d-flex align-center mt-15">
                    <div className="d-flex align-center m-auto">
                        <TabsMenu type="clients" />
                        <ButtonTab text="Relatório" active notification={false} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={3}></Col>
                <Col md={18}>
                    <Row gutter={[15,15]} className="mt-30">
                        <Col md={6}>
                            <Checkbox text="ASA" index={1} checked={asa} onChange={onChangeFilter} />
                        </Col>
                        <Col md={6}>
                            <Checkbox text="SPIRIT" index={2} checked={spirit} onChange={onChangeFilter} />
                        </Col>
                        <Col md={6}>
                            <Checkbox text="WALLET" index={3} checked={wallet} onChange={onChangeFilter} />
                        </Col>
                        <Col md={6}>
                            <Tooltip placement="right" title="Selecione ASA, Spirit ou Wallet para filtrar os clientes de cada produto ou deixe em branco para selecionar todos os clientes"><QuestionCircleOutlined style={{fontSize: 22, color: '#9dd600'}} /></Tooltip>
                        </Col>
                    </Row>
                    <Row className='mt-10'>
                        <Col md={24}>
                            <hr />
                        </Col>
                    </Row>
                    <Row gutter={[15,15]} className="mt-25">
                        {stateColumns.map((i,p) => (
                            <Col md={6} key={p}>
                                <Checkbox text={i.title} checked={i.checked} index={p} onChange={onClick} />
                            </Col>
                        ))}
                    </Row>
                </Col>
                <Col md={3}></Col>
                <Col md={3}></Col>
                <Col md={21}>
                    {error && <small className='tx-red font-bold ml-5 mt-10' style={{display: 'block'}}>Selecione ao menos uma coluna.</small>}
                </Col>
            </Row>
            <Row className="mt-50">
                <Col md={6}></Col>
                <Col md={12}>
                    <div className="d-flex justify-between">
                        <Button type="action" className="mr-5" onClick={checkAll}>Marcar Todos</Button>
                        <Button type="primary" active className="ml-5" onClick={onSubmit}>Gerar Relatórios</Button>
                    </div>
                </Col>
                <Col md={6}></Col>
            </Row>
        </Layout>
    )
}

export default UsersReport
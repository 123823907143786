import React, { useState } from 'react'
import '../../assets/theme/theme.less'
import { LayoutComponent as Layout, TableComponent as Table, TabsMenuComponent as TabsMenu, ButtonTabsComponent as ButtonTab } from '../../components'
import { Row, Col, Modal } from 'antd'
import { ReactComponent as iconError } from '../../assets/img/error-red.svg'
import { ReactComponent as iconSuccess } from '../../assets/img/success-green.svg'
import Icon from '@ant-design/icons';
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getConversions } from '../../redux/slices/walletSlice'
import { maskCurrency } from '../../utils/functions'
import AnualFee from './anualFee'

const Wallet = () => {

    const dispatch = useDispatch()
    const { isLoading, conversions } = useSelector(state => state.wallets)
    const [openModal, setOpenModal] = useState(false)

    const handleModal = () => {
        var aux = openModal
        setOpenModal(!aux)
    }

    useEffect(() => {
        dispatch(getConversions())
    }, [dispatch])
    
    const columns = [
        {
            title: 'Nome',
            dataIndex: 'Wallet',
            key: 'name',
            align: 'center',
            width: '10%',
            render: wallet => wallet.Cliente.nome
        },
        {
            title: 'Tipo',
            dataIndex: 'tipo',
            key: 'type',
            align: 'center',
            width: '10%',
            filters: [
                {
                    text: <span>BTC > BRL</span>,
                    value: 1
                },
                {
                    text: <span>BRL > BTC</span>,
                    value: 2
                },
            ],
            onFilter: (value, record) => record.tipo === value,
            render: a => a === 1 ? "BTC > BRL" : "BRL > BTC",
        },
        {
            title: 'BTC',
            dataIndex: 'quantiaBTC',
            key: 'quantiaBTC',
            align: 'center',
            width: '10%',
            render: value => value+" BTC"
        },
        {
            title: 'BRL',
            dataIndex: 'quantiaBRL',
            key: 'quantiaBRL',
            align: 'center',  
            width: '10%',
            render: value => "R$ "+maskCurrency(value/100)
        },
        {
            title: 'Taxa TokenX',
            dataIndex: 'flowFee',
            key: 'flowFee',
            align: 'center',
            width: '10%',
            render: (a, record) => record.tipo === 1 ? a+" BTC" : "R$"+maskCurrency(a/100)
        },
        {
            title: 'Data conversão',
            dataIndex: 'createdAt',
            key: 'createdAt',
            align: 'center',
            width: '10%',
            render: data => moment(data).format("DD/MM/YYYY HH:mm")
        },
        {
            title: 'Transaction ID',
            dataIndex: 'transactionId',
            key: 'transactionId',
            align: 'center',
            width: '15%',
        },
        {
            title: 'ID Pagamento',
            dataIndex: 'idPagamento',
            key: 'idPagamento',
            align: 'center',
            width: '5%',
        },
        {
            title: 'ID Fee',
            dataIndex: 'idFee',
            key: 'idFee',
            align: 'center',
            width: '10%',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            width: '10%',
            render: s => s === 3 ? <Icon className="full-monitor-icon" component={iconSuccess} /> : <Icon component={iconError} />
        },
    ]

    return (
        <Layout width>
            <Row>
                <Col md={24}>
                    <h1 className="title page-title">Wallet</h1>
                </Col>
                <Col md={24} className="mt-15 d-flex align-center">
                    <div className="d-flex align-center m-auto">
                        <TabsMenu type="wallet" />
                        <div onClick={handleModal}><ButtonTab text="Taxa Anual" active={false} notification={undefined} /></div>
                    </div>
                </Col>
                <Col md={24} className="mt-50" id="operations-table">
                    <Table loading={isLoading} column={columns} data={conversions} />
                </Col>
            </Row>
            <Modal
                visible={openModal}
                centered
                footer={null}
                closable={false}
                maskClosable={false}
                className="modal-deny"
                width="50vw"
            >
                <AnualFee handleModal={handleModal} />
            </Modal>
        </Layout>
    )
}

export default Wallet
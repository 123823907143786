import { request } from "../redux/api/config"
import moment from 'moment'
import { getInfoLocalStorage } from "../redux/api/config"
import jsPDF from "jspdf"
import logoConvertido from './img64'
import 'jspdf-autotable';

const stateFromLocalStorage = getInfoLocalStorage() 

export const downloadFile = async (e) => {
    try {
        const response = await request('operacional/getSignedURL/'+e+'/binary', 'GET')
        if (response.code === 51) {
            let link = document.createElement('a')
            link.id = 'download-file'
            link.setAttribute('href', response.url);
            link.setAttribute('download', e);
            document.body.appendChild(link)
            document.querySelector('#download-file').click()
            document.getElementById('download-file').remove()
        } else {
            return false
        }
    } catch(e) {
        return false
    }
}

export const getFileUrl = async (e) => {
    try {
        const response = await request('operacional/getSignedURL/'+e+'/image', 'GET')
        if (response.code === 51) {
            return response.url
        } else {
            return false
        }
    } catch(e) {
        return false
    }
}

export const generateReport = async (columns, type, filter, range) => {
    try {
        const response = await request('operacional/gerarRelatorio', 'POST', {
            columns, type, filter, range
        })
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        var fileName = 'report_'+moment().toDate().getTime()+'.csv'
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    } catch (e) {
        return false
    }
}

export const generateStatement = async (filter, type, range, contrato) => {
    try {
        const response = await request('operacional/gerarRelatorio', 'POST', {
            filter: filter,
            type: type,
            adminId: stateFromLocalStorage.id,
            idContrato: contrato.id,
            tipoContrato: parseInt(contrato.tipoContrato),
            range: range,
            dataInicio: contrato.dataInicio
        })
        if(response.code === 51) {
            try {
                let data = response.data
                let hash = response.hash
                let doc = new jsPDF()
    
                var col = ["Data","Valor", "Rent. diária", "Rent. acumulada", "Aportes", "Resgates"];
                var rows = [];
                var date = moment().format("DD/MM/YYYY HH:mm:ss");
    
                const produto = contrato.tipoContrato == 6 ? 'Spirit' : 'ASA'
    
                data.forEach(element => {     
                    var temp = [element.dataOperacao,element.totalFlow, element.rentabilidadeDiariaNormal, element.rentabilidadeMes, element.aporte, element.resgate];
                    rows.push(temp);
                }); 
                doc.addImage(logoConvertido[0].image, 'JPEG', 7, 10, 37, 12)
                doc.setFontSize(12);
                doc.text(70, 15, "Extrato "+contrato.Cliente.nome+" - TokenX Digital Assets "+produto);
                doc.text(70, 22, "Início: "+moment(contrato.dataInicio).format("DD/MM/YYYY"));
                if(parseInt(contrato.tipoContrato) !== 6) {
                    doc.text(70, 27, "Fim: "+moment(contrato.dataFim).format("DD/MM/YYYY"));
                }
                doc.text(70, 32, "Data de emissão: "+date);
                doc.autoTable(col, rows, { startY: 37, margin: { horizontal: 7 },  headStyles: { textColor: 255 }, didParseCell: function (rows) {
                    if(rows.row.raw === 'body') {
                        if (rows.row.index === 0 || rows.row.index%30 === 0) {
                            rows.cell.styles.fillColor = [209, 120, 245]
                        }
                    }
                    if(rows.column.raw === 'Aportes' && rows.row.section === 'body' && rows.row.raw[5] !== null){
                        rows.cell.styles.textColor = [36, 179, 34]   
                    }
                    if(rows.column.raw === 'Resgates' && rows.row.section === 'body' && rows.row.raw[6] !== null){
                        rows.cell.styles.textColor = [179, 34, 34]  
                    }
                  },});
                doc.setFontSize(9);
                doc.text(7, doc.autoTable.previous.finalY+30, "As informações contidas neste extrato abrangem somente até um dia antes da data de solicitação.\nExemplo: se o contrato foi solicitado hoje, somente as informações até ontem serão exibidas")
                doc.text(7, doc.autoTable.previous.finalY+40, "Autenticação: "+hash);
                doc.save("Extrato - "+contrato.Cliente.nome+"_"+produto+"-"+moment().format("DD/MM/YYYY")+".pdf")
            } catch(e) {
                console.log(e)
            }
        }
    } catch (e) {
        return false
    }
}

export const maskCpf = (value) => {
    try {
        return (value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4"))
    } catch (e) {
        return false
    }    
} 

export const maskTelefone = (value) => {
    try {
        if (value.length == 11){
            return (value.replace(/(\d{0})(\d{2})(\d{5})/g, "\$1(\$2) \$3-"))
        } else {
            return (value.replace(/(\d{0})(\d{2})(\d{4})/g, "\$1(\$2) \$3-"))
        }
    } catch (e) {
        return false
    }    
} 

export const maskCurrency = (value) => {
    try {
        value = Number(value).toFixed(2) + '';
        value = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value).replace('R$', '')  
        return value;
    } catch (e) {
        return false
    }
}


export const maskCep = value => {
    try {
        value = value.replace(/(\d{0,8})[\s\S]*/, '$1')
        .replace(/(\d{5})(\d)/, '$1-$2')
        return value
    } catch(e) {
        return false
    }
    
}